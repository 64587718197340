import { Col, Row, Section } from 'components/grid'
import Layout from 'components/Layout'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'

export default function BestBooksPage() {
	return (
		<Layout pageName="Best Books by Pedro Noguera">
			<Section>
				<Row>
					<StaticImage src="../../static/images/best-books-by-pedro-noguera/pedro-banner.png" alt="best books banner" />
				</Row>
				<Row className="flex-col-reverse items-end md:flex-row md:items-start" overflow={false}>
					<Col width="w-full md:w-2/3" className="mt-10">
						<p className="mb-0 text-2xl italic">
							“By helping kids to fall in love with reading, we make it possible for them to grow and expand their horizons. Access to great books is the key to
							making that happen.”
						</p>
						<span className="mt-6 block text-right">—Dr. Pedro Noguera</span>
						<div className="mt-12 text-slate-700">
							<p className="text-slate-700">
								Writer, speaker, well-respected educator, <Link to="/academic-advisory-council/#pedro-antonio-noguera">Dr. Pedro Noguera</Link> has partnered with
								American Reading Company to create independent reading collections hand-selected from the best fiction, non-fiction, and poetry available on the
								publishing market.
							</p>
							<p className="text-slate-700">
								These 50-book collections honor all students by affirming diverse voices, identities, and experiences through a variety of formats and genres. The
								contributions of both historically underrepresented figures and the changemakers of today are celebrated. Enduring literary classics and current
								bestsellers for students in grades K-8 are offered in these independent reading collections.
							</p>
							<p className="text-slate-700">
								The Best Books by Dr. Pedro Noguera independent reading collections will help educators across the country spread Dr. Noguera’s vision for
								engagement and excellence for all students.
							</p>
							<p className="text-slate-700">
								To learn more about the Best Books by Dr. Pedro Noguera at each grade band, we've created a visual guide which includes representative titles.
							</p>
						</div>
					</Col>
					<Col width="w-full md:w-1/3" className="-mt-20 flex flex-col items-end">
						<div className="flex w-56 flex-col items-end md:w-full">
							<StaticImage src="../../static/images/best-books-by-pedro-noguera/pedro-headshot.png" alt="pedro noguera headshot" />
							<div>
								<span className="mt-4 block text-right text-2xl">Dr. Pedro Noguera</span>
								<span className="block text-right">ARC Academic Advisory Council Member</span>
							</div>
							<div className="mt-4 flex justify-end gap-2">
								<a
									href="https://www.facebook.com/people/Pedro-Noguera/pfbid02WMokpwH2o5w67WrBzMnJidNRsqCd47UCUhFzotM5a5gvphyMSfx9bJbPd7XSVn9Vl/"
									className="mr-2 flex items-center justify-center text-[#3b5998]"
									target="_blank"
									rel="noopener noreferrer"
									aria-label="Visit pedro noguera's facebook profile"
								>
									<svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" viewBox="0 0 24 24" fill="currentColor">
										<path
											fillRule="evenodd"
											clipRule="evenodd"
											d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm3 8h-1.35c-.538 0-.65.221-.65.778v1.222h2l-.209 2h-1.791v7h-3v-7h-2v-2h2v-2.308c0-1.769.931-2.692 3.029-2.692h1.971v3z"
										/>
									</svg>
								</a>
								<a
									href="https://twitter.com/PedroANoguera"
									className="mr-2 flex items-center justify-center text-[#00acee]"
									target="_blank"
									rel="noopener noreferrer"
									aria-label="Visit pedro noguera's twitter profile"
								>
									<svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" viewBox="0 0 24 24" fill="currentColor">
										<path
											fillRule="evenodd"
											clipRule="evenodd"
											d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z"
										/>
									</svg>
								</a>
							</div>
						</div>
					</Col>
				</Row>
			</Section>
			<Section>
				<Row>
					<Col width="w-full md:w-1/3" className="mb-6">
						<StaticImage src="../../static/images/best-books-by-pedro-noguera/pedro-basket-red.jpg" alt="pedro noguera red basket of books" />
						<span className="block text-center font-semibold">Early Elementary</span>
					</Col>
					<Col width="w-full md:w-1/3" className="mb-6">
						<StaticImage src="../../static/images/best-books-by-pedro-noguera/pedro-basket-orange.jpg" alt="pedro noguera orange basket of books" />
						<span className="block text-center font-semibold">Intermediate Elementary</span>
					</Col>
					<Col width="w-full md:w-1/3">
						<StaticImage src="../../static/images/best-books-by-pedro-noguera/pedro-basket-bronze.jpg" alt="pedro noguera bronze basket of books" />
						<span className="block text-center font-semibold">Middle School</span>
					</Col>
				</Row>
			</Section>
		</Layout>
	)
}
